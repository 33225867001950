var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "화학물질 컬럼 정보" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.columnSetting,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 위험성평가 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "끓는점",
                                          falseLabel: "끓는점",
                                          name: "boilingPoint",
                                        },
                                        model: {
                                          value: _vm.columnSetting.boilingPoint,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "boilingPoint",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.boilingPoint",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "위험문구/유해위험문구 등급",
                                          falseLabel:
                                            "위험문구/유해위험문구 등급",
                                          name: "hrCodeGrade",
                                        },
                                        model: {
                                          value: _vm.columnSetting.hrCodeGrade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "hrCodeGrade",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.hrCodeGrade",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "유해･위험문구",
                                          falseLabel: "유해･위험문구",
                                          name: "hcode",
                                        },
                                        model: {
                                          value: _vm.columnSetting.hcode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "hcode",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.hcode",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "위험문구",
                                          falseLabel: "위험문구",
                                          name: "rcode",
                                        },
                                        model: {
                                          value: _vm.columnSetting.rcode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "rcode",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.rcode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "CMR 물질 여부",
                                          falseLabel: "CMR 물질 여부",
                                          name: "cmrFlag",
                                        },
                                        model: {
                                          value: _vm.columnSetting.cmrFlag,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "cmrFlag",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.cmrFlag",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "발암성물질",
                                          falseLabel: "발암성물질",
                                          name: "carcinogens",
                                        },
                                        model: {
                                          value: _vm.columnSetting.carcinogens,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "carcinogens",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.carcinogens",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "변이원성물질",
                                          falseLabel: "변이원성물질",
                                          name: "mutagens",
                                        },
                                        model: {
                                          value: _vm.columnSetting.mutagens,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "mutagens",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.mutagens",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "생식독성물질",
                                          falseLabel: "생식독성물질",
                                          name: "reproductiveToxins",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .reproductiveToxins,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "reproductiveToxins",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.reproductiveToxins",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "유해성 발생형태",
                                          falseLabel: "유해성 발생형태",
                                          name: "maleficenceForm",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.maleficenceForm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "maleficenceForm",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.maleficenceForm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "유해성 측정값",
                                          falseLabel: "유해성 측정값",
                                          name: "maleficenceValue",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.maleficenceValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "maleficenceValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.maleficenceValue",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "비산성 등급",
                                          falseLabel: "비산성 등급",
                                          name: "maleficenceGrade",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.maleficenceGrade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "maleficenceGrade",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.maleficenceGrade",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          disabled: true,
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "비산/휘발성 구분",
                                          falseLabel: "비산/휘발성 구분",
                                          name: "maleficenceType",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.maleficenceType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "maleficenceType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.maleficenceType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 유해화학물질 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "제한사항",
                                          falseLabel: "제한사항",
                                          name: "restrictions",
                                        },
                                        model: {
                                          value: _vm.columnSetting.restrictions,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "restrictions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.restrictions",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "금지사항",
                                          falseLabel: "금지사항",
                                          name: "prohibited",
                                        },
                                        model: {
                                          value: _vm.columnSetting.prohibited,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prohibited",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prohibited",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "사고대비물질_제조사용 지정수량(Kg)",
                                          falseLabel:
                                            "사고대비물질_제조사용 지정수량(Kg)",
                                          name: "accidentManuQuantity",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .accidentManuQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "accidentManuQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.accidentManuQuantity",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "사고대비물질_보관저장 지정수량(kg)",
                                          falseLabel:
                                            "사고대비물질_보관저장 지정수량(kg)",
                                          name: "accidentStoreQuantity",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .accidentStoreQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "accidentStoreQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.accidentStoreQuantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-td", {
                                    staticClass:
                                      "text-center th-td-style td-style",
                                    attrs: { rowspan: 1, colspan: 1 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 제외조건 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "내용",
                                          falseLabel: "내용",
                                          name: "exclusionConditions",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .exclusionConditions,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "exclusionConditions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.exclusionConditions",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "부등호",
                                          falseLabel: "부등호",
                                          name: "exclusionConditionSign",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .exclusionConditionSign,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "exclusionConditionSign",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.exclusionConditionSign",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "정량값",
                                          falseLabel: "정량값",
                                          name: "exclusionConditionQuantity",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .exclusionConditionQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "exclusionConditionQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.exclusionConditionQuantity",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "LBLUNIT",
                                          falseLabel: "LBLUNIT",
                                          name: "exclusionConditionUnit",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .exclusionConditionUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "exclusionConditionUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.exclusionConditionUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-td", {
                                    staticClass:
                                      "text-center th-td-style td-style",
                                    attrs: { rowspan: 1, colspan: 1 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" PRTR ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "Group_ID",
                                          falseLabel: "Group_ID",
                                          name: "prtrGroupId",
                                        },
                                        model: {
                                          value: _vm.columnSetting.prtrGroupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrGroupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrGroupId",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "물질명",
                                          falseLabel: "물질명",
                                          name: "prtrMaterialName",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.prtrMaterialName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrMaterialName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrMaterialName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상무게함유기준(wt%)",
                                          falseLabel:
                                            "조사대상무게함유기준(wt%)",
                                          name: "prtrSwcsWt",
                                        },
                                        model: {
                                          value: _vm.columnSetting.prtrSwcsWt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsWt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsWt",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상무게함유기준_부등호",
                                          falseLabel:
                                            "조사대상무게함유기준_부등호",
                                          name: "prtrSwcsWtSign",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.prtrSwcsWtSign,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsWtSign",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsWtSign",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상무게함유기준_정량값",
                                          falseLabel:
                                            "조사대상무게함유기준_정량값",
                                          name: "prtrSwcsWtQuantity",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .prtrSwcsWtQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsWtQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsWtQuantity",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상무게함유기준_단위",
                                          falseLabel:
                                            "조사대상무게함유기준_단위",
                                          name: "prtrSwcsWtUnit",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.prtrSwcsWtUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsWtUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsWtUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상취급량기준(톤/년)",
                                          falseLabel:
                                            "조사대상취급량기준(톤/년)",
                                          name: "prtrSwcsTon",
                                        },
                                        model: {
                                          value: _vm.columnSetting.prtrSwcsTon,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsTon",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsTon",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상취급량기준_부등호",
                                          falseLabel:
                                            "조사대상취급량기준_부등호",
                                          name: "prtrSwcsTonSign",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.prtrSwcsTonSign,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsTonSign",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsTonSign",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "조사대상취급량기준_정량값",
                                          falseLabel:
                                            "조사대상취급량기준_정량값",
                                          name: "prtrSwcsTonQuantity",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .prtrSwcsTonQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsTonQuantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsTonQuantity",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "조사대상취급량기준_단위",
                                          falseLabel: "조사대상취급량기준_단위",
                                          name: "prtrSwcsTonUnit",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.prtrSwcsTonUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "prtrSwcsTonUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.prtrSwcsTonUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 위험물 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "위험물내용",
                                          falseLabel: "위험물내용",
                                          name: "dangerousContents",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.dangerousContents,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "dangerousContents",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.dangerousContents",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "위험물유별구분",
                                          falseLabel: "위험물유별구분",
                                          name: "dangerousClass",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.dangerousClass,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "dangerousClass",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.dangerousClass",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "위험물성질",
                                          falseLabel: "위험물성질",
                                          name: "dangerousProp",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.dangerousProp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "dangerousProp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.dangerousProp",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "위험물품명",
                                          falseLabel: "위험물품명",
                                          name: "dangerousName",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.dangerousName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "dangerousName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.dangerousName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "지정수량_정량값",
                                          falseLabel: "지정수량_정량값",
                                          name: "designatedQuantityDose",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .designatedQuantityDose,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "designatedQuantityDose",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.designatedQuantityDose",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "지정수량_단위",
                                          falseLabel: "지정수량_단위",
                                          name: "designatedQuantityUnit",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .designatedQuantityUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "designatedQuantityUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.designatedQuantityUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 노출기준 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "공정안전관리 제조∙취급∙저장량(kg/일)",
                                          falseLabel:
                                            "공정안전관리 제조∙취급∙저장량(kg/일)",
                                          name: "psmAmount",
                                        },
                                        model: {
                                          value: _vm.columnSetting.psmAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "psmAmount",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.psmAmount",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "생물학적노출기준(단위포함 Full text)",
                                          falseLabel:
                                            "생물학적노출기준(단위포함 Full text)",
                                          name: "bei",
                                        },
                                        model: {
                                          value: _vm.columnSetting.bei,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "bei",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.bei",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "과민성 여부 입력(고용노동부, ACGIH 등)",
                                          falseLabel:
                                            "과민성 여부 입력(고용노동부, ACGIH 등)",
                                          name: "sensitization",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.sensitization,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "sensitization",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.sensitization",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "질식제 여부 입력(ACGIH, EU 노출기준 등)",
                                          falseLabel:
                                            "질식제 여부 입력(ACGIH, EU 노출기준 등)",
                                          name: "simpleAsphyxiants",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.simpleAsphyxiants,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "simpleAsphyxiants",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.simpleAsphyxiants",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "피부영향 여부 입력(고용노동부, ACGIH 등)",
                                          falseLabel:
                                            "피부영향 여부 입력(고용노동부, ACGIH 등)",
                                          name: "skinNotations",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting.skinNotations,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "skinNotations",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.skinNotations",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "표적장기 입력(예, 간, 신장 등)",
                                          falseLabel:
                                            "표적장기 입력(예, 간, 신장 등)",
                                          name: "basisCriticalEffects",
                                        },
                                        model: {
                                          value:
                                            _vm.columnSetting
                                              .basisCriticalEffects,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "basisCriticalEffects",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.basisCriticalEffects",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "TWA 노출기준입력(only 숫자만) ppm",
                                          falseLabel:
                                            "TWA 노출기준입력(only 숫자만) ppm",
                                          name: "twaPpm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.twaPpm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "twaPpm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.twaPpm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "TWA 노출기준입력(only 숫자만) mg/m3",
                                          falseLabel:
                                            "TWA 노출기준입력(only 숫자만) mg/m3",
                                          name: "twaMm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.twaMm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "twaMm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.twaMm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "STEL 노출기준입력(only 숫자만) ppm",
                                          falseLabel:
                                            "STEL 노출기준입력(only 숫자만) ppm",
                                          name: "stelPpm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.stelPpm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "stelPpm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.stelPpm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "STEL 노출기준입력(only 숫자만) mg/m3",
                                          falseLabel:
                                            "STEL 노출기준입력(only 숫자만) mg/m3",
                                          name: "stelMm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.stelMm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "stelMm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.stelMm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "Celling 노출기준입력(only 숫자만) ppm",
                                          falseLabel:
                                            "Celling 노출기준입력(only 숫자만) ppm",
                                          name: "ceilingsPpm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.ceilingsPpm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "ceilingsPpm",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.ceilingsPpm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "Celling 노출기준입력(only 숫자만) mg/m3",
                                          falseLabel:
                                            "Celling 노출기준입력(only 숫자만) mg/m3",
                                          name: "ceilingsMm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.ceilingsMm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "ceilingsMm",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "columnSetting.ceilingsMm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "LEL노출기준입력(only 숫자만) ppm",
                                          falseLabel:
                                            "LEL노출기준입력(only 숫자만) ppm",
                                          name: "lelPpm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.lelPpm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "lelPpm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.lelPpm",
                                        },
                                      }),
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "LEL노출기준입력(only 숫자만) mg/m3",
                                          falseLabel:
                                            "LEL노출기준입력(only 숫자만) mg/m3",
                                          name: "lelMm",
                                        },
                                        model: {
                                          value: _vm.columnSetting.lelMm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "lelMm",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.lelMm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isDense: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [_c("span", [_vm._v(" 그 외 ")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "고시내용 외 참조내용 입력(예, CMR, PBT 등)",
                                          falseLabel:
                                            "고시내용 외 참조내용 입력(예, CMR, PBT 등)",
                                          name: "remark1",
                                        },
                                        model: {
                                          value: _vm.columnSetting.remark1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "remark1",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.remark1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel:
                                            "적용기준, 고시내용 외 추가 참조사항 입력",
                                          falseLabel:
                                            "적용기준, 고시내용 외 추가 참조사항 입력",
                                          name: "remark2",
                                        },
                                        model: {
                                          value: _vm.columnSetting.remark2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "remark2",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.remark2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          editable: _vm.editable,
                                          isFlag: true,
                                          trueLabel: "기타 참조사항",
                                          falseLabel: "기타 참조사항",
                                          name: "remark3",
                                        },
                                        model: {
                                          value: _vm.columnSetting.remark3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.columnSetting,
                                              "remark3",
                                              $$v
                                            )
                                          },
                                          expression: "columnSetting.remark3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }